<template>
  <b-sidebar
    id="add-new-form-sidebar"
    :visible="isShowSlidebar"
    bg-variant="white"
    width="70%"
    shadow
    backdrop
    header-class="header-slide"
    title="TRẢ KẾT QUẢ XÉT NGHIỆM"
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-show-slidebar', val)"
    @shown="handleOpen"
  >
    <template #default>
      <div class="h-100">
        <div style="flex: auto" class="result">
          <div
            class="order-info flex items-center justify-between text-base px-4 py-2"
          >
            <span>
              <b>Đơn hàng:</b>
              {{ selectedOrder && selectedOrder.order_code }} -
              {{ selectedOrder && selectedOrder.contact_name }}
            </span>
            <span>
              <b>Thời gian đặt:</b>
              {{
                appUtils.formatDateTime(
                  selectedOrder && selectedOrder.order_date
                )
              }}
            </span>
          </div>
          <div class="result-content h-100">
            <SupplierUpdateResult
              :formUpload="formUpload"
              :listDisabled="listDisabled"
              :orderSelected="selectedOrder"
              :listOrderItems="selectedOrder && selectedOrder.order_item"
              :isLoadingReturnResult="isLoadingReturnResult"
              @result_supplier="setResultSupplier"
              @cancel="isShowSlidebar = false"
              @cancel-form="handleCancelForm"
            ></SupplierUpdateResult>
          </div>
        </div>
      </div>
    </template>
    <template #footer="{ hide }">
      <div
        class="flex align-items-center px-2 py-1 justify-content-end gap-3 footer"
      >
        <b-button variant="outline-secondary" @click="hide">Đóng</b-button>
        <b-button
          variant="primary"
          @click="handleSave"
          :busy="isLoadingReturnResult"
          >Lưu</b-button
        >
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import appUtils from "@/utils/appUtils";
import { RESULT_TYPE } from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import SupplierUpdateResult from "./SupplierUpdateResult.vue";

export default {
  name: "SlidebarReturnResultSupplier",
  components: { SupplierUpdateResult },
  data() {
    return {
      listOrders: [],
      keyword: "",
      appUtils,
      isShowLayoutUpdate: false,
      isDisabledBtn: false,
      processorResult: [],
      supplierResult: [],
      isLoading: false,
      isLoadingReturnResult: false,
      RESULT_TYPE,
    };
  },
  props: {
    isShowSlidebar: {
      type: Boolean,
      required: true,
    },
    selectedOrder: Object,
    formUpload: Array,
    listDisabled: Array,
  },
  watch: {
    selectedOrder() {
      this.isShowLayoutUpdate = false;
    },
  },
  computed: {
    //
  },
  methods: {
    resetForm() {
      //
    },
    handleOpen() {
      this.isShowLayoutUpdate = false;
    },
    handleSelectOrder(item) {
      this.selectedOrder = item;
    },
    handleUpdate() {
      this.isShowLayoutUpdate = true;
    },
    setResultSupplier(result) {
      this.supplierResult = result;
    },
    // Lưu kết quả đã tải lên
    async handleSave() {
      try {
        let attachments = [];
        // thông báo khi có ô input chưa tải file lên
        if (
          this.formUpload.filter((elm) => {
            return (
              elm.results.filter((item) => !item.id && item.file === null)
                .length > 0
            );
          }).length > 0
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Bạn cần tải lên file kết quả",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return;
        }
        // Nếu đã tải file đầy đủ
        else {
          this.isLoadingReturnResult = true;
          // upload file
          for (let i = 0; i < this.supplierResult.length; i++) {
            if (this.supplierResult[i].results?.find((elm) => !elm.id)) {
              await this.uploadFile(
                this.supplierResult[i].results?.find((elm) => !elm.id)?.file
              ).then((url) => {
                let item = {
                  attachment_ids: this.supplierResult[i]?.listServices?.length
                    ? this.supplierResult[i]?.listServices
                    : [],
                  url: url,
                  group_name: "Phiếu kết quả",
                  // group_name: this.supplierResult[i].name,
                  order_id: this.selectedOrder?.order_id,
                };
                if (this.supplierResult[i].id)
                  item.group_id = this.supplierResult[i].id;
                attachments.push(item);
              });
            }
          }
          const params = {
            attachments: [...attachments],
            type: this.RESULT_TYPE.SUPPLIER, // type: PROCESSOR / SUPPLIER
          };
          await Vue.prototype.$rf
            .getRequest("CommonRequest")
            .processorReturnResult(params)
            .then(async () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Lưu thành công",
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
              this.isLoadingReturnResult = false;
              this.$emit("success");
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // upload file
    async uploadFile(file) {
      const params = {
        file,
        FolderTarget: `certificates`,
        IdTarget: this.selectedOrder?.id,
      };
      const formData = new FormData();

      for (const key in params) {
        formData.append(key, params[key]);
      }

      const res = await this.$rf
        .getRequest("CommonRequest")
        .uploadFileTenant(formData);
      return res.data.fileUrl;
    },
    handleCancelForm(value) {
      this.$emit("cancel-form", value);
    },
  },
};
</script>
<style lang="scss">
.header-slide {
  height: 80px;
  // display: flex;
  justify-content: space-between;
  flex-direction: row-reverse !important;
  padding: 8px 24px !important;
  border-bottom: 1px solid #dbdbdb;
  .close {
    margin-right: unset !important;
  }
}
</style>
<style lang="scss" scoped>
.text-base {
  font-size: 16px;
  line-height: 24px;
}
.footer {
  border-top: 1px solid #dbdbdb;
  margin-left: 0 !important;
}
.sidebar {
  padding: 16px;
  width: 250px;
  // border-right: 1px solid #dbdbdb;
  position: sticky;
  flex: none;
  top: 0;
  // overflow: auto;
  border-right: 1px solid #dbdbdb;
  height: calc(100vh - 138px);
}
.result-content {
  display: flex;
  height: calc(100% - 66px);
}
</style>
